import styled from "styled-components";

export const StyledAbout = styled.section`
  .about {
    gap: 2rem;
    height: 100vh;
    margin: 0 auto;
  }
  .desc-container p {
    font-size: 2.5rem;
    font-weight: 500;
    color: #fdf6ee;
    font-family: "Montserrat", sans-serif;
    text-align: justify;
    line-height: 1.6;
  }
  .desc-container img {
    width: 4rem;
    position: relative;
    top: 4px;
    cursor: pointer;
  }
  .photo-container img {
    object-fit: cover;
    object-position: right;
    width: 50rem;
  }
  .hireme {
    text-align: center;
    margin-top: 2rem;
  }

  @media (max-width: 1024px) {
    .about {
      height: 70vh;
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      padding-right: 0;
    }
    .about {
      gap: 0;
      height: 50vh;
    }
    .desc-container {
      width: 55%;
    }

    .photo-container {
      width: 45%;
      img {
        width: 100%;
      }
    }
  }
  @media (max-width: 426px) {
    .about {
      height: max-content;
      margin-top: 5rem;
      gap: 5rem;
    }
    .flex-row {
      flex-direction: column-reverse;
    }
    .photo-container {
      width: 75%;
    }
    .desc-container {
      width: 75%;
      p {
        font-size: 2rem;
        font-weight: 400;
      }
      img {
        width: 3.5rem;
      }
    }
  }
`;
